import Button from "components/Button";
import CustomArrow from "assets/icons/CustomArrow";
import { useState } from "react";
import FavouriteList from "./FavouriteList";
import ActiveGigsList from "./ActiveGigsList";
import PendingGigsList from "./PendingGigsList";
import CancelledGigsList from "./CancelledGigsList";
import { BuildingStorefrontIcon } from "@heroicons/react/24/outline";

const GigsListing = () => {
  const [activeTab, setActiveTab] = useState("Favourite");
  return (
    <div className=''>
      <div className='flex items-center justify-between mt-5'>
        <div className='flex items-center gap-2'>
          <Button
            isActive={activeTab === "Favourite"}
            inActiveStyles='bg-white border border-mms-green-dark'
            inActiveTextStyles='text-mms-green-dark text-xs'
            onClick={() => setActiveTab("Favourite")}
            label='Favourite'
            containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            textStyles='text-white text-xs'
          />
          <Button
            isActive={activeTab === "Active Gigs"}
            inActiveStyles='bg-white border border-mms-green-dark'
            inActiveTextStyles='text-mms-green-dark text-xs'
            onClick={() => setActiveTab("Active Gigs")}
            label='Active Gigs'
            containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            textStyles='text-white text-xs'
          />
          <Button
            isActive={activeTab === "Pending Gigs"}
            inActiveStyles='bg-white border border-mms-green-dark'
            inActiveTextStyles='text-mms-green-dark text-xs'
            onClick={() => setActiveTab("Pending Gigs")}
            label='Pending Gigs'
            containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            textStyles='text-white text-xs'
          />
          <Button
            isActive={activeTab === "Cancelled Gigs"}
            inActiveStyles='bg-white border border-mms-green-dark'
            inActiveTextStyles='text-mms-green-dark text-xs'
            onClick={() => setActiveTab("Cancelled Gigs")}
            label='Cancelled Gigs'
            containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            textStyles='text-white text-xs'
          />
        </div>
        <div className='flex gap-2'>
          <div className='relative min-w-32'>
            <select className='block w-full appearance-none bg-white border border-mms-green-semi-light text-mms-green-medium py-2 pl-2 pr-10 rounded-lg shadow-sm focus:outline-none'>
              <option>All transactions</option>
              <option>Option 2</option>
              <option>Option 3</option>
            </select>
            <CustomArrow />
          </div>
          <Button
            isActive={true}
            icon={
              <BuildingStorefrontIcon className='size-5 text-white' />
            }
            label='Explore Gig Market'
            containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
            textStyles='text-white text-xs'
          />
        </div>
      </div>
      {/* {activeTab === "Favourite" && <FavouriteList />} */}
      {activeTab === "Favourite" && <FavouriteList />}
      {activeTab === "Active Gigs" && <ActiveGigsList />}
      {activeTab === "Pending Gigs" && <PendingGigsList />}
      {activeTab === "Cancelled Gigs" && <CancelledGigsList />}
    </div>
  );
};

export default GigsListing;
