import { no_notification } from "assets/images";
import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
import NotificationsList from "components/notification/NotificationsList";
import React from "react";

const Notifications: React.FC = () => {
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-full pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <div className='mt-5'>
            <div>
              <h3 className='font-bold'>Today</h3>
              <div className='mt-3 bg-[#EBEBEB40] flex items-center py-5 px-10 rounded-lg border border-gray-300'>
                <p className='w-full font-bold text-sm text-center'>
                  You do not have any Notifications
                </p>
                <img
                  src={no_notification}
                  alt='no notification'
                  className='w-36'
                />
              </div>
            </div>
            <div className='mt-5'>
              <h3 className='font-bold mt-5'>Yesterday</h3>
              <NotificationsList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
