import { AppContextProvider } from "./context/AppContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import UserContextProvider from "context/UserContextProvider";
import NotFound from "pages/NotFound";
import { Toaster } from "sonner";
import Dashboard from "pages/Dashboard";
import MainPage from "./pages/MainPage";
import Products from "pages/Products";
import Wallet from "pages/Wallet";
import AddNewProduct from "pages/AddNewProduct";
import Support from "pages/Support";
import SupportChat from "pages/SupportChat";
import Settings from "pages/Settings";
import Messages from "pages/Messages";
import GigsMarket from "pages/GigsMarket";
import Reviews from "pages/Reviews";
import Notifications from "pages/Notifications";
import SalesReport from "pages/SalesReport";
import Orders from "pages/Orders";
import Ads from "pages/Ads";
import EditProduct from "pages/EditProduct";
import BoostProduct from "pages/BoostProduct";

function App() {
  return (
    <AppContextProvider>
      <Toaster />
      <UserContextProvider>
        <BrowserRouter>
          <Routes>
            <Route index={true} element={<MainPage />} />
            <Route path={"/dashboard"} element={<Dashboard />} />
            <Route path={"/products"}>
              <Route index={true} element={<Products />} />
              <Route path={"add"} element={<AddNewProduct />} />
              <Route path={"edit"} element={<EditProduct />} />
              <Route path={"boost"} element={<BoostProduct />} />
            </Route>
            <Route path={"/sales-report"} element={<SalesReport />} />
            <Route path={"/wallet"} element={<Wallet />} />
            <Route path={"/reviews"} element={<Reviews />} />
            <Route path={"/gigs-market"} element={<GigsMarket />} />
            <Route path={"/messages"} element={<Messages />} />
            <Route path={"/notifications"} element={<Notifications />} />
            <Route path={"/orders"} element={<Orders />} />
            <Route path={"/ads"} element={<Ads />} />
            <Route path={"/support"}>
              <Route index={true} element={<Support />} />
              <Route path='chat' element={<SupportChat />} />
            </Route>
            <Route path={"/settings"} element={<Settings />} />
            {/*end- Protected routes */}

            {/*start- auth routes */}
            <Route path={"/login"} element={<Login />} />
            <Route path={"/register"} element={<Register />} />
            {/*end- auth routes */}

            <Route path={"*"} element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </UserContextProvider>
    </AppContextProvider>
  );
}

export default App;
