import React from "react";
interface PropsType {
  totalReviews: number;
  reviews: number;
  rating: number;
  label: string;
  style: string;
}
const ReviewSort: React.FC<PropsType> = ({
  totalReviews,
  reviews,
  rating,
  label,
  style,
}) => {
  return (
    <div className='flex gap-4 items-center'>
      <div className='w-2 h-2 bg-gray-300 rounded-full'></div>
      <span>{rating}</span>
      <div
        style={{ width: `${(reviews / totalReviews) * 100}%` }}
        className={`${style} h-1 rounded-full`}
      ></div>
      <span>{label}</span>
    </div>
  );
};

export default ReviewSort;
