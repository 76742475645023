import Button from "components/Button";
import FormField from "components/FormField";
import PopupOTP from "components/PopupOTP";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import { toast } from "sonner";
import * as Yup from "yup";

const initialValues: any = {
  firstname: "",
  lastname: "",
  email: "",
  phone_number: "",
};

const validationSchema = Yup.object({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  phone_number: Yup.string()
    .matches(/^[0-9]{10,}$/, "Phone number must be exactly 10 digits")
    .required("Required"),
});

const AccountInformationForm: React.FC = () => {
  const [emailVerified, setEmailVerified] = useState(false);
  const [popUp, setPopUp] = useState(false);
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          console.log(values);
          actions.setSubmitting(false);
        }}
      >
        {({ values, errors }) => (
          <div>
            <Form className='mt-5 flex flex-col w-full gap-3'>
              <div className=''>
                <FormField
                  id='first_name'
                  name='firstname'
                  label='First Name'
                  labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                  placeholder='First name'
                />
                <ErrorMessage
                  name='firstname'
                  component='div'
                  className='text-red-500 text-sm'
                />
              </div>
              <div>
                <FormField
                  id='last_name'
                  name='lastname'
                  label='Last Name'
                  labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                  placeholder='Last name'
                />
                <ErrorMessage
                  name='lastname'
                  component='div'
                  className='text-red-500 text-sm'
                />
              </div>
              <div>
                <FormField
                  id='phone_number'
                  name='phone_number'
                  label='Phone Number'
                  labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                  placeholder='Phone number'
                />
                <ErrorMessage
                  name='phone_number'
                  component='div'
                  className='text-red-500 text-sm'
                />
              </div>
              <div>
                <FormField
                  id='email'
                  name='email'
                  label='Email'
                  labelStyle="mb-1 font-bold relative after:content-['*'] after:text-red-500"
                  placeholder='tosingtoding@gmail.com'
                />
                <div
                  className='mt-1 text-sm underline text-mms-green-medium cursor-pointer'
                  onClick={() => {
                    if (values.email) setPopUp(true);
                    else {
                      toast.error("Enter email address.");
                    }
                  }}
                >
                  Verify email
                </div>
                <ErrorMessage
                  name='email'
                  component='div'
                  className='text-red-500 text-sm'
                />
              </div>
              <Button
                label='Update account'
                loadingText='Creating Account'
                type='submit'
                containerStyles='self-end bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
                textStyles='text-white'
              />
            </Form>
            {popUp && <PopupOTP email={values.email} setPopUp={setPopUp} />}
          </div>
        )}
      </Formik>
    </div>
  );
};

export default AccountInformationForm;
