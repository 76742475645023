import {
  BuildingStorefrontIcon,
  ChartBarIcon,
  ChatBubbleOvalLeftIcon,
  HomeIcon,
  MegaphoneIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  UserGroupIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import NavItem from "./NavItem";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { mms_logo } from "assets/icons";

const NavigationSideBar: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className='col-span-2 flex flex-col py-5 h-screen overflow-y-auto sticky top-0 left-0 pr-5 border-r border-gray-300 bg-white gap-2'>
      <div className='pl-7 sticky top-0 bg-white py-2'>
        <img src={mms_logo} alt='logo' className='w-24' />
      </div>
      <NavItem Icon={HomeIcon} pathname='/dashboard' label='Dashboard' />
      <NavItem pathname='/products' Icon={ShoppingBagIcon} label='Products' />
      <NavItem pathname='/services' Icon={ShoppingBagIcon} label='Services' />
      <NavItem
        pathname='/sales-report'
        Icon={ChartBarIcon}
        label='Sales Report'
      />
      <NavItem pathname='/wallet' Icon={WalletIcon} label='Wallet' />
      <NavItem pathname='/orders' Icon={ShoppingCartIcon} label='Orders' />
      <NavItem
        pathname='/messages'
        Icon={ChatBubbleOvalLeftIcon}
        label='Messages'
      />
      <NavItem
        pathname='/gigs-market'
        Icon={BuildingStorefrontIcon}
        label='Gigs Markets'
      />
      <NavItem pathname='/ads' Icon={MegaphoneIcon} label='Ads' />
      <NavItem pathname='/reviews' Icon={UserGroupIcon} label='Reviews' />
      {/* <NavItem Icon={Cog6ToothIcon} pathname='/settings' label='Settings' /> */}
      {/* <NavItem
        Icon={ChatBubbleLeftRightIcon}
        pathname='/support'
        label='Support'
      /> */}
      {/* <LogOut /> */}
      <div className='flex-1'></div>
      <p className='text-mms-green-medium text-sm pl-7 py-2'>Switch to buyer</p>
      <div className='flex ml-7 flex-col items-center gap-2 bg-mms-custom-pattern-upgrade bg-no-repeat bg-cover px-2 py-4 rounded-lg'>
        <p className='text-white text-xs text-center'>
          Upgrade your business account to get access to more features
        </p>
        <Button
          label='Upgrade account'
          containerStyles='bg-white hover:bg-mms-green-light active:bg-mms-green-medium disabled:bg-mms-gray-disabled'
          textStyles='text-mms-green-dark text-xs'
          onClick={() => navigate("/settings")}
        />
      </div>
    </div>
  );
};

export default NavigationSideBar;
