import Button from "components/Button";

const ProfileDropDown = () => {
  return (
    <div className='z-50 min-w-44 w-full absolute top-10 shadow-custom-heavy bg-mms-gray-light rounded-md'>
      <div className='p-2 border-b border-gray-200'>
        <p>View profile</p>
      </div>
      <div className='p-2 border-b border-gray-200'>
        <p>Store 1</p>
      </div>
      <div className='p-2 border-b border-gray-200'>
        <p>Store 2</p>
      </div>
      <div className='p-2 border-b border-gray-200'>
        <p>Store 3</p>
      </div>
      <Button
        label='Create store'
        containerStyles='w-full bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
        textStyles='text-white text-sm'
      />
    </div>
  );
};

export default ProfileDropDown;
