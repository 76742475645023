import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import AddNewProductForm from "components/AddNewProductForm";
import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
import { useNavigate } from "react-router-dom";

const AddNewProduct: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-full pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <div className='mt-5'>
            <div
              className='flex items-center gap-2 text-sm cursor-pointer'
              onClick={() => navigate("/products")}
            >
              <ArrowLeftIcon className='size-5' />
              <span>Back</span>
            </div>
           
            <AddNewProductForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewProduct;
