import { TotalSale } from "assets/icons";
import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
import ReviewList from "components/Reviews/ReviewList";
import ReviewSort from "components/Reviews/ReviewSort";
import StarsRating from "components/StarsRating";

const Reviews = () => {
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='flex flex-col pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <div className='mt-5 grid grid-cols-9 gap-4 justify-start'>
            <div className='col-span-2 border p-3 rounded-lg bg-white'>
              <img
                src={TotalSale}
                className='size-7 w-6'
                alt='total sale icon'
              />
              <p className='font-bold text-lg mt-2'>200,000</p>
              <p className='text-mms-green-medium'>Total Review</p>
              <p className='text-mms-orange-semi-medium text-xs mt-1'>
                +10% from last month
              </p>
            </div>
            <div className='col-span-3 border p-3 rounded-lg bg-white'>
              <img
                src={TotalSale}
                className='size-7 w-6'
                alt='total sale icon'
              />
              <p className='font-bold text-lg mt-2 text-mms-green-medium'>
                Average Rating
              </p>
              <p className='text-mms-orange-semi-medium text-xs my-1'>
                Rating for this month
              </p>
              <StarsRating rating={4} />
            </div>
            <div className='col-span-4 border p-3 rounded-lg bg-white'>
              <ReviewSort
                totalReviews={200000}
                rating={5}
                reviews={100000}
                label='100k'
                style='bg-mms-green-medium'
              />
              <ReviewSort
                totalReviews={200000}
                rating={4}
                reviews={50000}
                label='50k'
                style='bg-mms-blue-medium'
              />
              <ReviewSort
                totalReviews={200000}
                rating={3}
                reviews={20000}
                label='20k'
                style='bg-gray-500'
              />
              <ReviewSort
                totalReviews={200000}
                rating={2}
                reviews={15000}
                label='15k'
                style='bg-mms-orange-light'
              />
              <ReviewSort
                totalReviews={200000}
                rating={1}
                reviews={5000}
                label='5k'
                style='bg-red-300'
              />
            </div>
          </div>
          <ReviewList />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
