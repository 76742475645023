import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
import DeleteAccount from "components/SettingsTabComponents/SettingsTabs/DeleteAccount";
import PrivacyAndSafety from "components/SettingsTabComponents/SettingsTabs/PrivacyAndSafety";
import SettingsTabsNavigation from "components/SettingsTabComponents/SettingsTabsNavigation";

import AccountInformation from "components/SettingsTabComponents/SettingsTabs/AccountInformation";
import ChangePassword from "components/SettingsTabComponents/SettingsTabs/ChangePassword";
import { useState } from "react";
import PaymentAccount from "components/SettingsTabComponents/SettingsTabs/PaymentAccount";

const Settings = () => {
  const [settingsActiveTab, setSettingsActiveTab] = useState(
    "Account Information"
  );

  const handleSettingsTabClick = (tab: string) => {
    setSettingsActiveTab(tab);
  };
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-screen flex flex-col pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <div className='mt-5 gap-5'>
            <div className='relative flex-grow mt-6 grid grid-cols-12 items-start overflow-scroll'>
              <SettingsTabsNavigation
                activeTab={settingsActiveTab}
                handleTabClick={handleSettingsTabClick}
              />
              {settingsActiveTab === "Account Information" && (
                <AccountInformation />
              )}
              {settingsActiveTab === "Change Password" && <ChangePassword />}
              {settingsActiveTab === "Add Payment Account" && (
                <PaymentAccount />
              )}
              {settingsActiveTab === "Privacy and Safety" && (
                <PrivacyAndSafety />
              )}
              {settingsActiveTab === "Delete Account" && <DeleteAccount />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
