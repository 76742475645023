import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
import OrdersButton from "components/orders/OrdersButton";
import OrdersListing from "components/orders/OrdersListing";
import { useState } from "react";

const Orders = () => {
  const [activeTab, setActiveTab] = useState("Completed");
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-screen flex flex-col pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <OrdersButton activeTab={activeTab} setActiveTab={setActiveTab} />
          <OrdersListing />
        </div>
      </div>
    </div>
  );
};

export default Orders;
