import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
import GigsListing from "components/gigs_market/GigsListing";
import GigsMarketBanner from "components/gigs_market/GigsMarketBanner";
import React from "react";

const GigsMarket: React.FC = () => {
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-full pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <GigsMarketBanner />
          <GigsListing />
        </div>
      </div>
    </div>
  );
};

export default GigsMarket;
