import Button from "components/Button";
import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
import SupportQuestionNavigation from "components/SupportQuestionNavigation";
import SupportQuestionResponseDisplay from "components/SupportQuestionResponseDisplay";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Support: React.FC = () => {
  const navigate = useNavigate();
  const [supportActiveTab, setSupportActiveTab] = useState(0);
  const handleSupportTabClick = (tab: number) => {
    setSupportActiveTab(tab);
  };
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-screen flex flex-col pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <div className='mt-5 flex flex-col gap-2'>
            <h3 className='font-bold'>We're Here to Help</h3>
            <p className='text-gray-600'>
              Our dedicated customer support team is here to ensure you have a
              smooth and enjoyable shopping experience. Whether you have
              questions, encounter issues, or need assistance with any aspect of
              your order and general shopping experience we're ready to assist
              you.
            </p>
            <Button
              label='Chat with Support'
              containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled place-self-end'
              textStyles='text-white text-sm'
              onClick={() => {
                navigate("/support/chat");
              }}
            />
          </div>
          <div className='relative flex-grow mt-10 grid grid-cols-12 overflow-scroll'>
            <SupportQuestionNavigation
              activeTab={supportActiveTab}
              handleTabClick={handleSupportTabClick}
            />
            <SupportQuestionResponseDisplay
              activeTab={supportActiveTab}
              handleTabClick={handleSupportTabClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
