import MessageChatList from "components/MessageChatList";
import MessageChatListDisplay from "components/MessageChatDisplay";
import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
const Messages = () => {
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-screen flex flex-col pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <div className='flex-grow grid grid-cols-12 mt-10 gap-5'>
            <MessageChatList />
            <MessageChatListDisplay />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
