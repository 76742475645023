import DashboardEarnings from "components/DashboardEarnings";
import DashboardProductSold from "components/DashboardProductSold";
import DashboardSalesReport from "components/DashboardSalesReport";
import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
import ConversionRate from "components/sales_report/ConversionRate";
import CustomerSatisfaction from "components/sales_report/CustomerSatisfaction";
import DailySalesReport from "components/sales_report/DailySalesReport";
import KPITarget from "components/sales_report/KPITarget";
import TopProductSoldSection from "components/sales_report/TopProductSoldSection";
const SalesReport = () => {
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-full pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <DailySalesReport />
          <TopProductSoldSection />
          <div className='mt-5 grid grid-cols-3 gap-5 h-72'>
            <ConversionRate />
            <CustomerSatisfaction />
            <KPITarget />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesReport;
