import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
import AdsButton from "components/ads/AdsButton";
import AdsListing from "components/ads/AdsListing";
import { useState } from "react";

const Ads = () => {
  const [activeTab, setActiveTab] = useState("Ads Overview");
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-screen flex flex-col pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <AdsButton activeTab={activeTab} setActiveTab={setActiveTab} />
          <AdsListing activeTab={activeTab} />
        </div>
      </div>
    </div>
  );
};

export default Ads;
