import { useNavigate } from "react-router-dom";
import NavigationDropDownListItem from "./NavigationDropDownListItem";

const NavigationDropDown = () => {
  const navigate = useNavigate();
  return (
    <div className='z-50 min-w-72 w-full absolute top-10 -left-20 shadow-custom-heavy rounded-lg overflow-hidden bg-mms-gray-light rounded-l text-xs'>
      <div className='flex gap-5 items-center justify-between text-sm p-3'>
        <p className='font-semibold'>Notifications</p>
        <p>Mark all as read</p>
      </div>
      <NavigationDropDownListItem />
      <NavigationDropDownListItem />
      <NavigationDropDownListItem />
      <p
        className='p-3 cursor-pointer border-t border-gray-200'
        onClick={() => navigate("/notifications")}
      >
        See all notification
      </p>
    </div>
  );
};

export default NavigationDropDown;
