import { product_image } from "assets/images";
import { useState } from "react";
import ProductDetailsSideBar from "./ProductDetailsSideBar";

const ProductsListing = () => {
  const [showProductDetails, setShowProductDetails] = useState(false);
  return (
    <div className='mt-10 bg-white p-5 rounded-lg'>
      <div className='bg-mms-green-medium text-sm font-bold text-white grid grid-cols-7 items-center rounded-md p-2 mb-2'>
        <p className='col-span-2'>Product Information</p>
        <p>Price</p>
        <p>Quantity sold</p>
        <p>Stock at hand</p>
        <p>Total Revenue</p>
        <p>Date Created </p>
      </div>
      <div
        className='text-sm grid grid-cols-7 items-center rounded-lg p-2 hover:bg-gray-100 cursor-pointer shadow-gray-300 shadow-custom-light'
        onClick={() => setShowProductDetails(true)}
      >
        <p className='col-span-2 flex items-center gap-2'>
          <img
            src={product_image}
            className='border-[0.5px] border-gray-400 size-10 rounded-lg'
          />
          <div>
            <p className='font-semibold line-clamp-1 pr-3'>
              hermos Water Bottle- Flask hermos Water Bottle- Flask
            </p>
            <p className='font-light text-sm text-gray-500 line-clamp-1'>
              Categories: Fashion
            </p>
          </div>
        </p>
        <p>5,000</p>
        <p>15,000</p>
        <p>250,000</p>
        <p>75,000,000</p>
        <p>25/4/2024 , 5:23</p>
      </div>
      <div
        className='text-sm grid grid-cols-7 items-center rounded-lg p-2 mb-2 shadow-gray-300 shadow-custom-light hover:bg-gray-100'
        onClick={() => setShowProductDetails(true)}
      >
        <p className='col-span-2 flex items-center gap-2'>
          <img
            src={product_image}
            className='border-[0.5px] border-gray-400 size-10 rounded-lg'
          />
          <div>
            <p className='font-semibold line-clamp-1 pr-3'>
              hermos Water Bottle- Flask hermos Water Bottle- Flask
            </p>
            <p className='font-light text-sm text-gray-500 line-clamp-1'>
              Categories: Fashion
            </p>
          </div>
        </p>
        <p>5,000</p>
        <p>15,000</p>
        <p>250,000</p>
        <p>75,000,000</p>
        <p>25/4/2024 , 5:23</p>
      </div>
      {showProductDetails && (
        <div className='fixed top-0 right-0 left-0 bottom-0 bg-[#00000055]'></div>
      )}
      <ProductDetailsSideBar
        showProductDetails={showProductDetails}
        setShowProductDetails={setShowProductDetails}
      />
    </div>
  );
};

export default ProductsListing;
