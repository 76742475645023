import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import { no_ads } from "assets/icons";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";

const NoAds = () => {
  const navigate = useNavigate();
  return (
    <div className='mt-10 max-w-xl max-h-screen mx-auto h-full flex flex-col gap-2 items-center justify-center'>
      <img src={no_ads} className='w-64' />
      <h4 className='mt-5 font-bold text-xl'>No Adverts Yet!</h4>
      <p className='text-sm text-center'>
        Boost your visibility by sponsoring a post. To begin, navigate to your
        product page, select the item you wish to promote, and choose a
        sponsorship plan that aligns with your goals
      </p>
      <Button
        onClick={() => {
          navigate("/products");
        }}
        label='Go to Product Page'
        icon={<ShoppingBagIcon className='w-5 text-white' />}
        containerStyles='bg-mms-green-medium hover:bg-mms-green-semi-dark active:bg-mms-green-dark disabled:bg-mms-gray-disabled'
        textStyles='text-white text-sm'
      />
    </div>
  );
};

export default NoAds;
