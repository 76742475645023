import { NavLink } from "react-router-dom";

interface PropsType {
  Icon: any;
  label: string;
  pathname: string;
}
const NavItem: React.FC<PropsType> = ({ Icon, pathname, label }) => {
  return (
    <NavLink
      to={pathname}
      className={({ isActive }) =>
        `transition-all flex gap-3 text-sm items-center p-2 cursor-pointer hover:bg-mms-green-semi-light pl-7 rounded-r-md group hover:text-mms-green-dark font-normal ${
          isActive && "bg-mms-green-semi-light"
        }`
      }
    >
      <Icon className='size-5' />
      <span>{label}</span>
    </NavLink>
  );
};

export default NavItem;
