import { delete_account } from "assets/images";
import Button from "components/Button";
import { useState } from "react";
import AccountType from "./delete_account_tabs/AccountType";
import ReasonTab from "./delete_account_tabs/ReasonTab";
import ConfirmDeletion from "./delete_account_tabs/ConfirmDeletion";

const DeleteAccount = () => {
  const [activeTab, setActiveTab] = useState("account_type");
  const [accountType, setAccountType] = useState("");
  const [reason, setReason] = useState("");
  return (
    <>
      {activeTab === "account_type" && (
        <AccountType
          accountType={accountType}
          setActiveTab={setActiveTab}
          setAccountType={setAccountType}
        />
      )}
      {activeTab === "confirm_tab" && (
        <ConfirmDeletion
          reason={reason}
          setReason={setReason}
          setActiveTab={setActiveTab}
          accountType={accountType}
          setAccountType={setAccountType}
        />
      )}
      {activeTab === "reason_tab" && (
        <ReasonTab
          reason={reason}
          setReason={setReason}
          setActiveTab={setActiveTab}
          accountType={accountType}
          setAccountType={setAccountType}
        />
      )}
    </>
  );
};

export default DeleteAccount;
