import ProductButtons from "components/ProductButtons";
import ProductsListing from "components/ProductsListing";
import React from "react";
import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";

const Products: React.FC = () => {
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-full pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <ProductButtons />
          <ProductsListing />
        </div>
      </div>
    </div>
  );
};

export default Products;
