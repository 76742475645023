import DashboardEarnings from "components/DashboardEarnings";
import DashboardProductSold from "components/DashboardProductSold";
import DashboardSalesReport from "components/DashboardSalesReport";
import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import NavigationSideBar from "components/NavigationSideBar";
const Dashboard = () => {
  return (
    <div className='relative grid grid-cols-12 h-screen'>
      <NavigationSideBar />
      <div className='col-span-10 h-screen overflow-y-scroll'>
        <div className='h-full pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <DashboardSalesReport />
          <DashboardProductSold />
          <DashboardEarnings />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
