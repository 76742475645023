import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import GeneralTopSearchWithNotification from "components/GeneralTopSearchWithNotification";
import LocationSearch from "components/LocationSearch";
import NavigationSideBar from "components/NavigationSideBar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const BoostProduct: React.FC = () => {
  const [search, setSearch] = useState("");
  const [locations, setLocations] = useState<Array<string>>([]);
  const navigate = useNavigate();
  return (
    <div className='relative grid grid-cols-12'>
      <NavigationSideBar />
      <div className='col-span-10 h-full'>
        <div className='h-full pl-5 pr-10 py-2 bg-mms-gray-light'>
          <GeneralTopSearchWithNotification />
          <div className='mt-5 flex flex-col gap-5'>
            <div className='bg-white p-5'>
              <div
                className='flex items-center gap-2 text-sm cursor-pointer'
                onClick={() => navigate("/products")}
              >
                <ArrowLeftIcon className='size-5' />
                <span>Back</span>
              </div>
              <div className='mt-3'>
                <h3 className='font-bold text-xl'>Boost Product Post</h3>
                <p className='text-sm text-gray-600'>
                  Increase your product's visibility and reach more buyers by
                  boosting your listing.
                </p>
              </div>
            </div>
            <div className='bg-white p-5 flex flex-col gap-5'>
              <div>
                <h3 className='font-bold text-xl'>Audience</h3>
                <p className='text-sm text-gray-600'>Who should see your ad?</p>
              </div>
              <div>
                <h3 className='font-bold text-xl mb-2'>Location</h3>
                <LocationSearch
                  search={search}
                  setSearch={setSearch}
                  setLocations={setLocations}
                />
                <div className='mt-3 flex items-center gap-5'>
                  {locations?.length ? (
                    locations.map((location, idx) => (
                      <div className='w-fit py-1 px-2 rounded-md text-sm text-mms-green-dark flex items-center gap-2 bg-mms-green-extra-light'>
                        {location}
                        <XMarkIcon className='w-4 cursor-pointer' />
                      </div>
                    ))
                  ) : (
                    <div className='text-sm text-gray-500'>
                      No location selected
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoostProduct;
