import React, { ButtonHTMLAttributes, forwardRef } from "react";

interface PropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  containerStyles: string;
  textStyles: string;
  iconImage?: string | null;
  icon?: React.ReactNode;
  loading?: boolean;
  loadingText?: string;
  isActive?: boolean;
  inActiveStyles?: string;
  inActiveTextStyles?: string;
}

const Button = forwardRef<HTMLButtonElement, PropsType>(
  (
    {
      label,
      containerStyles,
      textStyles,
      iconImage,
      icon,
      type,
      loading,
      loadingText,
      isActive,
      inActiveStyles,
      inActiveTextStyles,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        disabled={loading}
        type={type}
        className={`${
          isActive === true || isActive === undefined
            ? containerStyles
            : inActiveStyles
        } flex gap-2 text-center py-2 px-6 rounded-md items-center justify-center font-medium`}
        {...props}
      >
        {iconImage && <img src={iconImage} className='w-5' alt='' />}
        {icon}
        <span
          className={`${
            isActive === true || isActive === undefined
              ? textStyles
              : inActiveTextStyles
          } font-medium`}
        >
          {loading && loadingText ? loadingText : label}
        </span>
      </button>
    );
  }
);

export default Button;
