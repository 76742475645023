import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React from "react";

interface PropTypes {
  inputStyles?: string;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setLocations: React.Dispatch<React.SetStateAction<Array<string>>>;
}
const LocationSearch: React.FC<PropTypes> = ({
  inputStyles,
  search,
  setSearch,
  setLocations,
}) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setLocations((prev) => [...prev, search]);
      setSearch("");
    }
  };
  return (
    <div
      className={`${
        inputStyles ? inputStyles : "bg-white"
      } w-full flex items-center gap-3 border-[0.5px] border-gray-300 rounded-lg px-2 py-3`}
    >
      <MagnifyingGlassIcon className='size-4 text-gray-500' />
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={handleKeyPress}
        className={`outline-none`}
        placeholder='Input city, State or Country.'
      />
    </div>
  );
};

export default LocationSearch;
